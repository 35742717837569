import React, { useState } from 'react';
import {
  RoundIconButton,
  Sidebar,
  Tooltip,
  Breadcrumbs,
  ModulesMenu,
} from '@skillfactory/frontend-theme';
import { ReactComponent as ModulesIcon } from '@skillfactory/frontend-theme/dist/assets/images/icons/modules.svg';

import { useSelector } from 'react-redux';

import SequenceTabView from '../sequence-tab-view/SequenceTabView';
import { useModel } from '../../generic/model-store';
import InstructorToolbar from '../../instructor-toolbar';

const getChapterUrl = (courseId, chapter) => {
  const firstSequence = chapter.subsections[0];
  if (!firstSequence) { return; }
  return `/learning/course/${courseId}/${firstSequence.subsectionId}`;
};

const CoursewarePage = ({
  courseId,
  sequenceId,
  unitId,
  nextSequenceHandler,
  previousSequenceHandler,
  unitNavigationHandler,
  courseStatus,
  sequenceStatus,
  handle,
}) => {
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const sections = useSelector(state => state.models.sections);
  console.log(sections);
  const sequences = useSelector(state => state.models.sequences);
  const units = useSelector(state => state.models.units);
  const sequence = useModel('sequences', sequenceId);

  const courseMenuItems = Object.values(sections).map(section => ({
    id: section.id,
    sectionName: section.title,
    subsections: section.sequenceIds.map(sequenceId => {
      const numberOfCompletedLessons = sequences[sequenceId]
        ?.unitIds
        ?.filter(unitId => units[unitId]?.complete)
        ?.length;
      return ({
        subsectionId: sequenceId,
        subsectionName: sequences[sequenceId].title,
        state: 'current', // TODO stub
        totalLessons: section.sequenceIds.length,
        currentLesson: numberOfCompletedLessons,
      });
    }),
  }));

  const {
    originalUserIsStaff,
    canViewLegacyCourseware,
    title: courseTitle,
  } = useModel('coursewareMeta', courseId);

  const selectedSectionIndex = courseMenuItems.findIndex(it => it.id === sequence.sectionId);

  return (
    <>
      {originalUserIsStaff && (
        <InstructorToolbar
          courseId={courseId}
          unitId={unitId}
          canViewLegacyCourseware={canViewLegacyCourseware}
          tab="courseware"
          className="sf-outline-page__instructor-toolbar"
        />
      )}
      <Breadcrumbs
        className="sf-courseware-page__breadcrumbs"
        items={[{
          label: courseTitle,
          link: `/learning/course/${courseId}/home`,
        }, {
          label: courseMenuItems[selectedSectionIndex].sectionName,
          link: getChapterUrl(courseId, courseMenuItems[selectedSectionIndex]),
        }, { label: sequence.title }]}
      />
      <div className="sf-courseware-page__courseware-container">
        <SequenceTabView
          className="sf-courseware-page__sequence-tab-view"
          courseId={courseId}
          sequenceId={sequenceId}
          courseStatus={courseStatus}
          unitId={unitId}
          section={{
            id: courseMenuItems[selectedSectionIndex].id,
            name: courseMenuItems[selectedSectionIndex].sectionName,
          }}
          nextSequenceHandler={nextSequenceHandler}
          previousSequenceHandler={previousSequenceHandler}
          unitNavigationHandler={unitNavigationHandler}
        />
        <Tooltip
          className="sf-courseware-page__modules-tooltip"
          content="Все модули курса"
          leftOffset={-29}
        >
          <RoundIconButton
            className="sf-courseware-page__modules-button"
            icon={<ModulesIcon />}
            onClick={() => setSidebarVisible(true)}
            size="large"
            variant="white"
          />
        </Tooltip>
        <Sidebar
          className="sf-courseware-page__modules-sidebar"
          isVisible={isSidebarVisible}
          onHide={() => setSidebarVisible(false)}
          header="Все модули"
        >
          <ModulesMenu
            sections={courseMenuItems}
            selectedSubsectionId={sequenceId}
            onSelectSubsection={handle}
          />
        </Sidebar>
      </div>
    </>
  );
};

export default CoursewarePage;
